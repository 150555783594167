<template>
  <div class="columns form is-mini-gap is-multiline">
    <div :class="'column ' + columnSize">
      <InputProperty
        v-model:value="propertyId"
        class="rate-filter-input"
      />
    </div>
    <div :class="'column ' + columnSize">
      <InputPlan
        v-model:value="plan"
        class="rate-filter-input"
      />
    </div>
    <div :class="'column ' + columnSize">
      <a-month-picker
        v-model:value="date"
        placeholder="Select month"
        format="MM/DD/YYYY"
        :value-format="dateFormat"
        :size="size"
        class="rate-filter-input is-mobile-field"
        @change="onMonthChange"
      />
    </div>
    <div :class="'column ' + columnSize">
      <div class="columns">
        <div class="column is-12">
          <a-button
            v-show="propertyId && plan"
            :size="size"
            style="min-width: 110px"
            type="primary"
            class="mr-3"
            @click="handleFilter"
          >
            {{ $t('Search') }}
          </a-button>
          <a-button
            v-show="propertyId || plan || date"
            :size="size"
            style="min-width: 110px"
            type="default"
            @click="handleClear"
          >
            {{ $t('Clear') }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Search": "Search",
    "Clear": "Clear",
    "Start Date" : "Start Date",
  },
  "ja": {
    "Search": "サーチ",
    "Clear": "クリア",
    "Start Date" : "Start Date",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import InputProperty from '@/components/InputProperty';
import { cleanObject } from '@/utils/util';
import InputPlan from './InputPlan';

export default {
  name: 'RatesFilter',
  components: {
    InputProperty,
    InputPlan,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    columnSize: {
      type: String,
      default: 'is-12',
    },
  },
  emits: ['filter', 'clear-filter'],
  data() {
    return {
      date: undefined,
      propertyId: undefined,
      plan: undefined,
    };
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.plan = this.$route.query.plan || undefined;
    this.date = this.$route.query.date || undefined;
  },
  methods: {
    onMonthChange(date) {
      this.date = dayjs(date).startOf('month').format(this.dateFormat);
    },
    async handleFilter() {
      if (!this.date) {
        this.date = dayjs().startOf('month').format(this.dateFormat);
      }

      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        plan: this.plan,
        date: this.date,
      });

      this.$emit('filter', query);
    },
    async handleClear() {
      this.propertyId = undefined;
      this.plan = undefined;
      this.date = undefined;

      await this.$router.push({ query: {} });

      this.$emit('clear-filter');
    },
  },
};
</script>

<style lang="scss" scoped>
.is-12::v-deep .rate-filter-input {
  min-width: 150px;
}

::v-deep {
  .ant-calendar-picker-input {
    padding-left: 2rem;
  }

  .ant-calendar-picker-icon {
    left: 12px;
    right:auto;
    color: #757575;
  }

  .ant-calendar-picker-input.ant-input {
    line-height: 1.6;
  }

  .ant-calendar-picker {
    width: 100%;
    @include tablet {
      width: 150px;
    }
    @media (min-width: 1500px) {
      width: 200px;
    }
  }
}
</style>
